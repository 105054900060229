define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-ca-province-select", ["exports", "@ember/object", "@ember-decorators/component", "discourse-i18n", "select-kit/components/combo-box", "select-kit/components/select-kit"], function (_exports, _object, _component, _discourseI18n, _comboBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubscribeCaProvinceSelect = dt7948.c(class SubscribeCaProvinceSelect extends _comboBox.default {
    nameProperty = "name";
    valueProperty = "value";
    get content() {
      return [["AB", "Alberta"], ["BC", "British Columbia"], ["MB", "Manitoba"], ["NB", "New Brunswick"], ["NL", "Newfoundland and Labrador"], ["NT", "Northwest Territories"], ["NS", "Nova Scotia"], ["NU", "Nunavut"], ["ON", "Ontario"], ["PE", "Prince Edward Island"], ["QC", "Quebec"], ["SK", "Saskatchewan"], ["YT", "Yukon"]].map(arr => {
        return {
          value: arr[0],
          name: arr[1]
        };
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
  }, [(0, _component.classNames)("subscribe-address-state-select"), (0, _selectKit.pluginApiIdentifiers)("subscribe-ca-province-select"), (0, _selectKit.selectKitOptions)({
    filterable: true,
    allowAny: false,
    translatedNone: (0, _discourseI18n.i18n)("discourse_subscriptions.subscribe.cardholder_address.province")
  })]);
  var _default = _exports.default = SubscribeCaProvinceSelect;
});