define("discourse/plugins/discourse-subscriptions/discourse/controllers/user-billing-subscriptions-card", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global Stripe */

  class UserBillingSubscriptionsCardController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    loading = false;
    saved = false;
    init() {
      super.init(...arguments);
      this.set("stripe", Stripe(this.siteSettings.discourse_subscriptions_public_key));
      const elements = this.get("stripe").elements();
      this.set("cardElement", elements.create("card", {
        hidePostalCode: true
      }));
    }
    async updatePaymentMethod() {
      this.set("loading", true);
      this.set("saved", false);
      const paymentMethodObject = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.cardElement
      });
      if (paymentMethodObject.error) {
        this.dialog.alert(paymentMethodObject.error?.message || (0, _discourseI18n.i18n)("generic_error"));
        this.set("loading", false);
        return;
      }
      const paymentMethod = paymentMethodObject.paymentMethod.id;
      try {
        await (0, _ajax.ajax)(`/s/user/subscriptions/${this.model}`, {
          method: "PUT",
          data: {
            payment_method: paymentMethod
          }
        });
        this.set("saved", true);
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.set("loading", false);
        this.cardElement?.clear();
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "updatePaymentMethod", [_object.action]))();
  }
  _exports.default = UserBillingSubscriptionsCardController;
});