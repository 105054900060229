define("discourse/plugins/discourse-subscriptions/discourse/models/subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Subscription extends _object.default {
    static show(id) {
      return (0, _ajax.ajax)(`/s/${id}`, {
        method: "get"
      });
    }
    canceled(status) {
      return status === "canceled";
    }
    static #_ = (() => dt7948.n(this.prototype, "canceled", [(0, _decorators.default)("status")]))();
    save() {
      const data = {
        source: this.source,
        plan: this.plan,
        promo: this.promo,
        cardholder_name: this.cardholderName,
        cardholder_address: this.cardholderAddress
      };
      return (0, _ajax.ajax)("/s/create", {
        method: "post",
        data
      });
    }
  }
  _exports.default = Subscription;
});