define("discourse/plugins/discourse-subscriptions/discourse/models/user-subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/models/plan"], function (_exports, _object, _ajax, _decorators, _discourseI18n, _plan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserSubscription extends _object.default {
    static findAll() {
      return (0, _ajax.ajax)("/s/user/subscriptions", {
        method: "get"
      }).then(result => result.map(subscription => {
        subscription.plan = _plan.default.create(subscription.plan);
        return UserSubscription.create(subscription);
      }));
    }
    canceled(status) {
      return status === "canceled";
    }
    static #_ = (() => dt7948.n(this.prototype, "canceled", [(0, _decorators.default)("status")]))();
    endDate(current_period_end, canceled_at) {
      if (!canceled_at) {
        return moment.unix(current_period_end).format("LL");
      } else {
        return (0, _discourseI18n.i18n)("discourse_subscriptions.user.subscriptions.cancelled");
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "endDate", [(0, _decorators.default)("current_period_end", "canceled_at")]))();
    discounted(discount) {
      if (discount) {
        const amount_off = discount.coupon.amount_off;
        const percent_off = discount.coupon.percent_off;
        if (amount_off) {
          return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
        } else if (percent_off) {
          return `${percent_off}%`;
        }
      } else {
        return (0, _discourseI18n.i18n)("no_value");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "discounted", [(0, _decorators.default)("discount")]))();
    destroy() {
      return (0, _ajax.ajax)(`/s/user/subscriptions/${this.id}`, {
        method: "delete"
      }).then(result => UserSubscription.create(result));
    }
  }
  _exports.default = UserSubscription;
});