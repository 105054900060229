define("discourse/plugins/discourse-subscriptions/discourse/components/payment-plan", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse/lib/decorators", "@ember/template-factory"], function (_exports, _component, _object, _component2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{action "planClick"}}
    class={{concat-class
      "btn-discourse-subscriptions-subscribe"
      this.selectedClass
    }}
  >
    <span class="interval">
      {{#if this.recurringPlan}}
        {{i18n
          (concat
            "discourse_subscriptions.plans.interval.adverb."
            this.plan.recurring.interval
          )
        }}
      {{else}}
        {{i18n "discourse_subscriptions.one_time_payment"}}
      {{/if}}
    </span>
  
    <span class="amount">
      {{format-currency this.plan.currency this.plan.amountDollars}}
    </span>
  </DButton>
  */
  {
    "id": "e2INHcof",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"btn-discourse-subscriptions-subscribe\",[30,0,[\"selectedClass\"]]],null]]],[[\"@action\"],[[28,[37,2],[[30,0],\"planClick\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[14,0,\"interval\"],[12],[1,\"\\n\"],[41,[30,0,[\"recurringPlan\"]],[[[1,\"      \"],[1,[28,[35,5],[[28,[37,6],[\"discourse_subscriptions.plans.interval.adverb.\",[30,0,[\"plan\",\"recurring\",\"interval\"]]],null]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[\"discourse_subscriptions.one_time_payment\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"amount\"],[12],[1,\"\\n    \"],[1,[28,[35,7],[[30,0,[\"plan\",\"currency\"]],[30,0,[\"plan\",\"amountDollars\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-button\",\"concat-class\",\"action\",\"span\",\"if\",\"i18n\",\"concat\",\"format-currency\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/components/payment-plan.hbs",
    "isStrictMode": false
  });
  const RECURRING = "recurring";
  const PaymentPlan = dt7948.c(class PaymentPlan extends _component.default {
    selectedClass(planId) {
      return planId === this.plan.id ? "btn-primary" : "";
    }
    static #_ = (() => dt7948.n(this.prototype, "selectedClass", [(0, _decorators.default)("selectedPlan")]))();
    recurringPlan(type) {
      return type === RECURRING;
    }
    static #_2 = (() => dt7948.n(this.prototype, "recurringPlan", [(0, _decorators.default)("plan.type")]))();
    planClick() {
      this.clickPlan(this.plan);
      return false;
    }
    static #_3 = (() => dt7948.n(this.prototype, "planClick", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaymentPlan);
});