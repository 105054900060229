define("discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/decorators"], function (_exports, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCoupon extends _object.default {
    static list() {
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "get"
      }).then(result => {
        if (result === null) {
          return {
            unconfigured: true
          };
        }
        return result.map(coupon => AdminCoupon.create(coupon));
      });
    }
    static save(params) {
      const data = {
        promo: params.promo,
        discount_type: params.discount_type,
        discount: params.discount,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "post",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    }
    static update(params) {
      const data = {
        id: params.id,
        active: params.active
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "put",
        data
      }).then(coupon => AdminCoupon.create(coupon));
    }
    static destroy(params) {
      const data = {
        coupon_id: params.coupon.id
      };
      return (0, _ajax.ajax)("/s/admin/coupons", {
        method: "delete",
        data
      });
    }
    discount(amount_off, percent_off) {
      if (amount_off) {
        return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
      } else if (percent_off) {
        return `${percent_off}%`;
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "discount", [(0, _decorators.default)("coupon.amount_off", "coupon.percent_off")]))();
  }
  _exports.default = AdminCoupon;
});