define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscribe-show", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/models/subscription", "discourse/plugins/discourse-subscriptions/discourse/models/transaction"], function (_exports, _controller, _object, _computed, _service, _decorators, _discourseI18n, _subscription, _transaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global Stripe */

  class SubscribeShowController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    selectedPlan = null;
    promoCode = null;
    cardholderName = null;
    cardholderAddress = {
      line1: null,
      city: null,
      state: null,
      country: null,
      postalCode: null
    };
    static #_3 = (() => dt7948.g(this.prototype, "isAnonymous", [(0, _computed.not)("currentUser")]))();
    #isAnonymous = (() => (dt7948.i(this, "isAnonymous"), void 0))();
    isCountryUS = false;
    isCountryCA = false;
    init() {
      super.init(...arguments);
      this.set("stripe", Stripe(this.siteSettings.discourse_subscriptions_public_key));
      const elements = this.get("stripe").elements();
      this.set("cardElement", elements.create("card", {
        hidePostalCode: true
      }));
      this.set("isCountryUS", this.cardholderAddress.country === "US");
      this.set("isCountryCA", this.cardholderAddress.country === "CA");
    }
    alert(path) {
      this.dialog.alert((0, _discourseI18n.i18n)(`discourse_subscriptions.${path}`));
    }
    canPurchase(repurchaseable, subscribed) {
      if (!repurchaseable && subscribed) {
        return false;
      }
      return true;
    }
    static #_4 = (() => dt7948.n(this.prototype, "canPurchase", [(0, _decorators.default)("model.product.repurchaseable", "model.product.subscribed")]))();
    createSubscription(plan) {
      return this.stripe.createToken(this.get("cardElement"), {
        name: this.cardholderName,
        // Recommended by Stripe
        address_line1: this.cardholderAddress.line1 ?? "",
        address_city: this.cardholderAddress.city ?? "",
        address_state: this.cardholderAddress.state ?? "",
        address_zip: this.cardholderAddress.postalCode ?? "",
        address_country: this.cardholderAddress.country // Recommended by Stripe
      }).then(result => {
        if (result.error) {
          this.set("loading", false);
          return result;
        } else {
          const subscription = _subscription.default.create({
            source: result.token.id,
            plan: plan.get("id"),
            promo: this.promoCode,
            cardholderName: this.cardholderName,
            cardholderAddress: this.cardholderAddress
          });
          return subscription.save();
        }
      });
    }
    handleAuthentication(plan, transaction) {
      return this.stripe.confirmCardPayment(transaction.payment_intent.client_secret).then(result => {
        if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
          return result;
        } else {
          this.set("loading", false);
          this.dialog.alert(result.error.message || result.error);
          return result;
        }
      });
    }
    _advanceSuccessfulTransaction(plan) {
      this.alert("plans.success");
      this.set("loading", false);
      this.router.transitionTo(plan.type === "recurring" ? "user.billing.subscriptions" : "user.billing.payments", this.currentUser.username.toLowerCase());
    }
    changeCountry(country) {
      this.set("cardholderAddress.country", country);
      this.set("isCountryUS", country === "US");
      this.set("isCountryCA", country === "CA");
    }
    static #_5 = (() => dt7948.n(this.prototype, "changeCountry", [_object.action]))();
    changeState(stateOrProvince) {
      this.set("cardholderAddress.state", stateOrProvince);
    }
    static #_6 = (() => dt7948.n(this.prototype, "changeState", [_object.action]))();
    stripePaymentHandler() {
      this.set("loading", true);
      const plan = this.get("model.plans").filterBy("id", this.selectedPlan).get("firstObject");
      const cardholderAddress = this.cardholderAddress;
      const cardholderName = this.cardholderName;
      if (!plan) {
        this.alert("plans.validate.payment_options.required");
        this.set("loading", false);
        return;
      }
      if (!cardholderName) {
        this.alert("subscribe.invalid_cardholder_name");
        this.set("loading", false);
        return;
      }
      if (!cardholderAddress.country) {
        this.alert("subscribe.invalid_cardholder_country");
        this.set("loading", false);
        return;
      }
      if (cardholderAddress.country === "US" && !cardholderAddress.state) {
        this.alert("subscribe.invalid_cardholder_state");
        this.set("loading", false);
        return;
      }
      if (cardholderAddress.country === "CA" && !cardholderAddress.state) {
        this.alert("subscribe.invalid_cardholder_province");
        this.set("loading", false);
        return;
      }
      let transaction = this.createSubscription(plan);
      transaction.then(result => {
        if (result.error) {
          this.dialog.alert(result.error.message || result.error);
        } else if (result.status === "incomplete" || result.status === "open") {
          const transactionId = result.id;
          const planId = this.selectedPlan;
          this.handleAuthentication(plan, result).then(authenticationResult => {
            if (authenticationResult && !authenticationResult.error) {
              return _transaction.default.finalize(transactionId, planId).then(() => {
                this._advanceSuccessfulTransaction(plan);
              });
            }
          });
        } else {
          this._advanceSuccessfulTransaction(plan);
        }
      }).catch(result => {
        this.dialog.alert(result.jqXHR.responseJSON.errors[0] || result.errorThrown);
        this.set("loading", false);
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "stripePaymentHandler", [_object.action]))();
  }
  _exports.default = SubscribeShowController;
});