define("discourse/plugins/discourse-subscriptions/discourse/helpers/format-currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;
  function formatCurrency(_ref) {
    let [currency, amount] = _ref;
    let currencySign;
    switch (currency.toUpperCase()) {
      case "EUR":
        currencySign = "€";
        break;
      case "GBP":
        currencySign = "£";
        break;
      case "INR":
        currencySign = "₹";
        break;
      case "BRL":
        currencySign = "R$";
        break;
      case "DKK":
        currencySign = "DKK";
        break;
      case "SGD":
        currencySign = "S$";
        break;
      case "ZAR":
        currencySign = "R";
        break;
      case "CHF":
        currencySign = "CHF";
        break;
      case "PLN":
        currencySign = "zł";
        break;
      case "CZK":
        currencySign = "Kč";
        break;
      default:
        currencySign = "$";
    }
    let formattedAmount = parseFloat(amount).toFixed(2);
    return currencySign + formattedAmount;
  }
  var _default = _exports.default = (0, _helper.helper)(formatCurrency);
});