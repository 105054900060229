define("discourse/plugins/discourse-subscriptions/discourse/initializers/setup-subscriptions", ["exports", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "setup-subscriptions",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.11", api => {
        const siteSettings = container.lookup("service:site-settings");
        const isNavLinkEnabled = siteSettings.discourse_subscriptions_extra_nav_subscribe;
        const isPricingTableEnabled = siteSettings.discourse_subscriptions_pricing_table_enabled;
        const subscribeHref = isPricingTableEnabled ? "/s/subscriptions" : "/s";
        if (isNavLinkEnabled) {
          api.addNavigationBarItem({
            name: "subscribe",
            displayName: (0, _discourseI18n.i18n)("discourse_subscriptions.navigation.subscribe"),
            href: subscribeHref
          });
        }
        const user = api.getCurrentUser();
        if (user) {
          api.addQuickAccessProfileItem({
            icon: "far-credit-card",
            href: `/u/${user.username}/billing/subscriptions`,
            content: "Billing"
          });
        }
      });
    }
  };
});