define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscribe-index", ["exports", "@ember/controller", "discourse/lib/decorators", "discourse/models/user"], function (_exports, _controller, _decorators, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubscribeIndexController extends _controller.default {
    isLoggedIn() {
      return _user.default.current();
    }
    static #_ = (() => dt7948.n(this.prototype, "isLoggedIn", [(0, _decorators.default)()]))();
  }
  _exports.default = SubscribeIndexController;
});