define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing-subscriptions-index", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-subscriptions/discourse/models/user-subscription"], function (_exports, _object, _route, _service, _discourseI18n, _userSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserBillingSubscriptionsIndexRoute extends _route.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model() {
      return _userSubscription.default.findAll();
    }
    updateCard(subscriptionId) {
      this.router.transitionTo("user.billing.subscriptions.card", subscriptionId);
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateCard", [_object.action]))();
    cancelSubscription(subscription) {
      this.dialog.yesNoConfirm({
        message: (0, _discourseI18n.i18n)("discourse_subscriptions.user.subscriptions.operations.destroy.confirm"),
        didConfirm: () => {
          subscription.set("loading", true);
          subscription.destroy().then(result => subscription.set("status", result.status)).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
            subscription.set("loading", false);
            this.refresh();
          });
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "cancelSubscription", [_object.action]))();
  }
  _exports.default = UserBillingSubscriptionsIndexRoute;
});